import React, { FC } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';

import { safeLazy } from '@core/router';
import { Profile } from '@modules/auth/model';
import Layout from '@layout/Layout';
import AppRoutes from '@modules/app/routes';
import { renderOptional } from '@shared/utils/render';
import { useAuthContext } from '@modules/auth/context';
import { Routes } from '@core/router/Routes';

const LoginPage = safeLazy(() => import('@modules/auth/LoginPage'));
const ForgotPasswordPage = safeLazy(() => import('@modules/auth/ForgotPasswordPage'));
const ResetPasswordPage = safeLazy(() => import('@modules/auth/ResetPasswordPage'));
const ActivationPage = safeLazy(() => import('@modules/auth/ActivationPage'));

interface PrivateRoutesProps {
  profile: Profile;
}

const PrivateRoutes: FC<PrivateRoutesProps> = ({ profile }) => (
  <Routes>
    <Route element={<Layout profile={profile} />}>
      <Route path="/*" element={<AppRoutes profile={profile} />} />
    </Route>
  </Routes>
);

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();
  let location = useLocation();

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/password-reset/forgot" element={<ForgotPasswordPage />} />
      <Route path="/password-reset/:token" element={<ResetPasswordPage />} />
      <Route path="/activation/:token" element={<ActivationPage />} />
      <Route
        path="/*"
        element={
          <>
            {renderOptional(
              profile,
              profile => (
                <PrivateRoutes profile={profile} />
              ),
              () => (
                <Navigate to="/login" state={{ referrer: location }} />
              ),
            )}
          </>
        }
      />
    </Routes>
  );
};

export default RootRoutes;
