import React, { FC } from 'react';

import { safeLazy } from '@core/router';
import { Profile } from '@modules/auth/model';
import { Navigate, Route } from 'react-router-dom';
import { Routes } from '@core/router/Routes';
import { StaticPageType } from '@modules/app/links/model';

const EditorialsRoutes = safeLazy(() => import('@modules/app/editorials/routes'));
const LinksRoutes = safeLazy(() => import('@modules/app/links/routes'));
const LegalsRoutes = safeLazy(() => import('@modules/app/legals/routes'));

interface AppRoutesProps {
  profile: Profile;
}

const AppRoutes: FC<AppRoutesProps> = ({ profile }) => (
  <Routes>
    <Route path="/editorials/*" element={<EditorialsRoutes />} />
    <Route path="/links" element={<LinksRoutes />} />
    <Route path="/about-us/*" element={<LegalsRoutes pageType={StaticPageType.AboutUs} />} />
    <Route path="/legals/*" element={<LegalsRoutes pageType={StaticPageType.Legals} />} />
    <Route path="/gdpr/*" element={<LegalsRoutes pageType={StaticPageType.GDPR} />} />
    <Route path="/" element={<Navigate to="/editorials" replace />} />
  </Routes>
);

export default AppRoutes;
