import { createStyles } from '@mantine/core';

const styles = createStyles(theme => ({
  header: {
    minHeight: 70,

    a: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',

      img: {
        height: 60,
      },
    },
  },

  content: {
    background: theme.colors[theme.primaryColor][5],
  },

  group: {
    padding: '5px 0 15px',

    p: {
      marginBottom: 5,
      padding: '0 15px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: theme.white,
      fontSize: 16,
    },
  },

  entries: {
    padding: '25px 15px 5px 15px',
    margin: 0,
    listStyle: 'none',
    fontSize: 15,

    li: {
      paddingBottom: 7,

      a: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 15px',
        color: theme.white,
        borderRadius: 6,
        background: 'transparent',
        transition: 'all 0.2s ease-in-out',
        textDecoration: 'none',

        '&:hover': {
          background: theme.fn.rgba(theme.white, 0.1),
        },

        '&.active': {
          background: theme.fn.rgba(theme.white, 0.2),
        },
      },
    },
  },

  profile: {
    color: theme.colors[theme.primaryColor][7],
  },

  footer: {
    display: 'grid',
    gridTemplateColumns: '1fr 28px',
    gridColumnGap: 10,
    alignItems: 'center',
    boxShadow: '0 -2px 6px 0 rgba(0, 0, 0, 0.04)',

    a: {
      minWidth: 0,

      div: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}));

export default styles;
