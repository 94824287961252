import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';

const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Open Sans, sans-serif',
        colors: {
          blue: [
            '#6F90AB',
            '#5684AA',
            '#4579A4',
            '#346FA0',
            '#23669E',
            '#115E9D',
            '#00579F',
            '#0E4D81',
            '#17446A',
            '#1C3D58',
          ],
          orange: [
            '#F2DFD6',
            '#EEC5B0',
            '#F2AB87',
            '#FF9057',
            '#EB8049',
            '#D57340',
            '#BC683D',
            '#9E6142',
            '#865A44',
            '#735343',
          ],
        },
        primaryColor: 'blue',
        primaryShade: 5,
      }}
      withNormalizeCSS
      withGlobalStyles
    >
      <NotificationsProvider position="top-right">{children}</NotificationsProvider>
    </MantineProvider>
  );
};

export default MantineTheme;
