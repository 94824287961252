import {
  EditorialId,
  EditorialLanguage,
  EditorialLinkedPageType,
  EditorialState,
  EditorialType,
} from '@modules/app/editorials/model';

export enum StaticPageType {
  AboutUs = 'about-us',
  GDPR = 'gdpr',
  Legals = 'legals',
}

export type LinksPageType = EditorialLinkedPageType | StaticPageType;

export interface Links {
  id: EditorialId;
  title: string;
  availableLanguages: Array<EditorialLanguage>;
  state: EditorialState;
  createdAt: string;
  type: EditorialType;
}
